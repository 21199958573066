import { Loader } from '@mantine/core';
import { noop } from 'lodash/fp';
import React, { useMemo, useRef } from 'react';
import { useLocalStorage } from 'react-use';

import { DeviceType, SpaceType, useSpace } from '@portals/api/organizations';
import { buildTreeFromNodes, expandNodesByDepth } from '@portals/utils';

import { Tree } from './components/Tree';

interface SpaceTreeProps {
  spaces: SpaceType[];
  handleSelected: (nodeId: number) => void;
  selected: SpaceType | null;
  readonly?: boolean;
  draggable?: boolean;
  openModal: (type: string, params?: any) => void;
  spaceId?: DeviceType['space_id'];
  hideCreateCustomerSpaceButton?: boolean;
}

export function SpaceTree({
  spaces,
  handleSelected,
  selected,
  readonly,
  spaceId,
  hideCreateCustomerSpaceButton,
  draggable,
}: SpaceTreeProps) {
  const treeData = useMemo(() => buildTreeFromNodes(spaces), [spaces]);

  const space = useSpace({ spaceId });

  const filteredTree = useMemo(() => {
    if (!space) {
      return treeData;
    }

    return treeData[0].children.filter(
      (node) => node.root_customer_space_id === space?.root_customer_space_id
    );
  }, [space, treeData]);

  // Load expanded nodes from local storage if exists
  const [storedExpandedNodeIds] = useLocalStorage(
    'spacesTree.expandedNodes',
    null
  );
  // Set expanded nodes from local storage if exists, or use default 3 level expansion
  const initialExpandedNodeIds = useRef(
    storedExpandedNodeIds || expandNodesByDepth(filteredTree, 3)
  );

  if (!treeData) {
    return <Loader />;
  }

  return treeData ? (
    <Tree
      draggable={draggable}
      hideCreateCustomerSpaceButton={hideCreateCustomerSpaceButton}
      spaces={spaces}
      treeData={filteredTree}
      handleSelected={handleSelected}
      handleMoveSpace={noop}
      readonly={readonly}
      initialExpandedNodeIds={initialExpandedNodeIds.current}
      selected={selected?.id}
    />
  ) : null;
}
