import { Button, Modal, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useMemo } from 'react';

import { useCreateSpace, useSpaces } from '@portals/api/organizations';
import { ModalFooter } from '@portals/core';
import { ModalProps } from '@portals/framework';

import { findRoot } from '../../lib/spaces';

export interface CreateCustomerSpaceModalProps extends ModalProps {}

export function CreateCustomerSpaceModal({
  closeMe,
}: CreateCustomerSpaceModalProps) {
  const spaces = useSpaces();
  const createSpace = useCreateSpace();

  const form = useForm({
    initialValues: {
      customerName: '',
    },
  });

  const rootSpace = useMemo(() => {
    if (!spaces.data) {
      return;
    }

    return findRoot(spaces.data);
  }, [spaces.data]);

  const onSubmit = (values: typeof form.values) => {
    if (!rootSpace) {
      return;
    }

    createSpace.mutate(
      {
        parentSpaceId: rootSpace.id,
        newSpace: {
          name: values.customerName,
        },
      },
      { onSuccess: () => closeMe() }
    );
  };

  return (
    <Modal opened onClose={closeMe} title="Create new customer" padding="xxl">
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="xxl">
          <Text>
            Creating multiple customers lets you separate devices belonging to
            different organizations. You can create separate integrations for
            each customer, but you cannot move devices or spaces between
            customers.
          </Text>

          <Stack spacing="xl">
            <Text c="gray.6" fz="lg" ta="center">
              What is the customer’s name?
            </Text>

            <TextInput
              required
              data-autofocus
              placeholder="Customer's name"
              {...form.getInputProps('customerName')}
            />
          </Stack>
        </Stack>

        <ModalFooter position="right" pt="xxl">
          <Button variant="default" onClick={closeMe}>
            Cancel
          </Button>

          <Button
            type="submit"
            disabled={!form.values.customerName.trim()}
            loading={createSpace.isLoading}
          >
            Create customer
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
