import { Box, createStyles } from '@mantine/core';
import React from 'react';

import { SearchInput } from '@portals/core';

import { CreateCustomerSpaceButton } from './CreateCustomerSpaceButton';

interface TreeHeaderProps {
  handleSearchOnChange: (value: string) => void;
  searchString: string;
  isReadonly?: boolean;
}

export function TreeHeader({
  handleSearchOnChange,
  searchString,
  isReadonly = false,
}: TreeHeaderProps) {
  const { classes } = useStyles({ isReadonly });

  return (
    <Box className={classes.container} px="md">
      <SearchInput
        value={searchString}
        placeholder="Find space..."
        onClear={() => handleSearchOnChange('')}
        onChange={(event) => handleSearchOnChange(event.target.value)}
        size="md"
        w="100%"
        classNames={{
          root: classes.inputRoot,
          input: classes.input,
        }}
      />

      {!isReadonly && <CreateCustomerSpaceButton />}
    </Box>
  );
}

const useStyles = createStyles(
  (theme, { isReadonly }: { isReadonly: boolean }) => ({
    container: {
      display: 'grid',
      gridTemplateColumns: isReadonly ? '1fr' : '1fr min-content',
      alignItems: 'center',
      gap: theme.spacing.xs,
    },

    inputRoot: {
      height: 40,
    },

    input: {
      minHeight: 40,
      height: 40,
      borderColor: theme.colors.gray[3],
    },
  })
);
