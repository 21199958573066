import {
  Box,
  createStyles,
  Divider,
  Group,
  Stack,
  Tooltip,
} from '@mantine/core';
import React, { Dispatch, SetStateAction } from 'react';
import { contextMenu, Menu } from 'react-contexify';
import { useNavigate } from 'react-router-dom';

import {
  SpaceType,
  useSpace,
  useUpdateSpace,
} from '@portals/api/organizations';
import { ForbiddenErrorSVG } from '@portals/assets';
import { useConfirmationModal, usePermissionAccess } from '@portals/framework';
import { ReactComponent as BoxAdd } from '@portals/icons/linear/box-add.svg';
import { ReactComponent as Edit } from '@portals/icons/linear/edit.svg';
import { ReactComponent as MonitorMobile } from '@portals/icons/linear/monitor-mobbile.svg';
import { ReactComponent as Settings } from '@portals/icons/linear/setting-2.svg';
import { ReactComponent as Snooze } from '@portals/icons/linear/snooze.svg';
import { ReactComponent as Trash } from '@portals/icons/linear/trash.svg';
import { useOpenModal } from '@portals/redux';
import { suppressPropagation } from '@portals/utils';

import { canAdmin } from '../../../../../../lib/access';
import { useOpenClaimDeviceModal } from '../../../../../components/ClaimDeviceButton';
import { DeleteCustomerSpaceModalProps } from '../../../../../modals';

interface CustomerNodeContextMenuProps {
  nodeId: SpaceType['id'];
  onEditToggle: () => void;
  onCreateSpace: () => void;
  setIsContextMenuOpen: Dispatch<SetStateAction<boolean>>;
  canDeleteCustomer: boolean;
}

export function CustomerNodeContextMenu({
  nodeId,
  onEditToggle,
  onCreateSpace,
  setIsContextMenuOpen,
  canDeleteCustomer,
}: CustomerNodeContextMenuProps) {
  const { classes, cx } = useStyles();
  const openModal = useOpenModal();

  const asyncConfirmationCheck = useConfirmationModal();
  const navigate = useNavigate();
  const { isAdmin } = usePermissionAccess();
  const openClaimDeviceModal = useOpenClaimDeviceModal();

  const space = useSpace({ spaceId: nodeId });
  const updateSpace = useUpdateSpace();

  if (!space) return null;

  const isUnderMaintenance = space.state?.maintenance || false;

  const handleSetMaintenance = async (enabled: boolean) => {
    if (enabled) {
      const isConfirmed = await asyncConfirmationCheck({
        title: `Maintenance Mode Activation`,
        description: `Maintenance mode will turn off all incident notifications. This will allow you to perform maintenance and updates without disrupting your work. Do you want to continue?`,
        confirmationLabel: 'Turn ON',
        cancelLabel: 'Cancel',
      });

      if (isConfirmed) {
        updateSpace.mutate({
          spaceId: space.id,
          updatedSpace: { maintenance: true },
        });
      }
    } else {
      updateSpace.mutate({
        spaceId: space.id,
        updatedSpace: { maintenance: false },
      });
    }
  };

  return (
    <Menu
      id={nodeId}
      onVisibilityChange={(isVisible) => setIsContextMenuOpen(isVisible)}
    >
      <>
        <Stack className={classes.container} spacing="xs" pt="xs">
          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              handleSetMaintenance(!isUnderMaintenance);
              contextMenu.hideAll();
            })}
          >
            <Snooze />
            Set maintenance {isUnderMaintenance ? 'OFF' : 'ON'}
          </Group>

          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              openClaimDeviceModal({ spaceId: space.id });
              contextMenu.hideAll();
            })}
          >
            <MonitorMobile />
            Claim device
          </Group>

          <Group
            className={classes.item}
            spacing="xs"
            onClick={() => {
              onCreateSpace();
              contextMenu.hideAll();
            }}
          >
            <BoxAdd />
            Add new space
          </Group>

          <Divider color="gray.3" />

          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              onEditToggle();
              contextMenu.hideAll();
            })}
          >
            <Edit />
            Rename
          </Group>

          <Group
            className={classes.item}
            spacing="xs"
            onClick={suppressPropagation(() => {
              navigate(`/overview/${space.id}/settings/access`);
              contextMenu.hideAll();
            })}
          >
            <ForbiddenErrorSVG />
            Manage Access
          </Group>

          {isAdmin || canAdmin(space) ? (
            <Group
              className={classes.item}
              spacing="xs"
              onClick={suppressPropagation(() => {
                navigate(`/overview/${space.id}/settings/space`);
                contextMenu.hideAll();
              })}
            >
              <Settings />
              Settings
            </Group>
          ) : null}

          <Divider color="gray.3" />

          <Tooltip
            label="You must have at least one customer"
            disabled={canDeleteCustomer}
            position="bottom"
            withArrow
            offset={20}
          >
            <Box>
              <Group
                className={cx(classes.item, classes.removeItem, {
                  [classes.disabled]: !canDeleteCustomer,
                })}
                spacing="xs"
                onClick={suppressPropagation(() => {
                  if (!canDeleteCustomer) {
                    return;
                  }

                  contextMenu.hideAll();

                  openModal<DeleteCustomerSpaceModalProps['data']>(
                    'DeleteCustomerSpaceModal',
                    {
                      customerName: space.name,
                      spaceId: space.id,
                    }
                  );
                })}
              >
                <Trash />
                Delete customer
              </Group>
            </Box>
          </Tooltip>
        </Stack>
      </>
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    zIndex: 999,
    padding: '0 4px',
    width: 224,
  },

  item: {
    flexShrink: 0,
    height: 33,
    padding: `0 ${theme.spacing.lg}`,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
    fontWeight: 300,
    transition: 'all 0.15s ease-in-out',
    borderRadius: theme.radius.sm,
    cursor: 'pointer',

    svg: {
      height: 18,
      width: 18,
    },

    ':hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },

  removeItem: {
    color: theme.colors.red_accent[4],
  },

  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
    pointerEvents: 'none',
    color: theme.colors.gray[6],
  },
}));
