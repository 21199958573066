import {
  Box,
  Center,
  createStyles,
  Group,
  LoadingOverlay,
  SegmentedControl,
  Stack,
  Tooltip,
} from '@mantine/core';
import { includes } from 'lodash/fp';
import React, { useEffect, useMemo } from 'react';

import { useConfig, useSpaces } from '@portals/api/organizations';
import { FeatureNotificationEnum, useCurrentUser } from '@portals/api/ui';
import { FeaturesNotificationsProps } from '@portals/framework/modals';
import { ReactComponent as AddIcon } from '@portals/icons/linear/add.svg';
import { ReactComponent as DateIcon } from '@portals/icons/linear/data-2.svg';
import { ReactComponent as MouseSquare } from '@portals/icons/linear/mouse-square.svg';
import { useOpenModal } from '@portals/redux';

import { DashboardWrapper } from './DashboardWrapper';
import { useOverviewContext } from '../overview.context';
import { useCurrentSpace } from '../overview.hooks';
import { DataLevelEnum } from '../overview.types';

export function OverviewDashboard() {
  const overview = useOverviewContext();
  const config = useConfig();

  const currentSpace = useCurrentSpace();
  const spaces = useSpaces();

  const currentUser = useCurrentUser();
  const openModal = useOpenModal();

  const styles = useStyles({ isConfigMode: overview.isConfigMode });

  useEffect(() => {
    if (
      includes(
        FeatureNotificationEnum.NewSystemIntegratorsExperience,
        currentUser?.data?.feature_notifications
      )
    ) {
      openModal<FeaturesNotificationsProps['data']>('FeaturesNotifications', {
        featureNotification:
          FeatureNotificationEnum.NewSystemIntegratorsExperience,
      });
    }
  }, [currentUser?.data?.feature_notifications, openModal]);

  const isSpaceHasChildren = useMemo(
    () => spaces.data?.some((space) => space.parent_id === currentSpace?.id),
    [spaces, currentSpace]
  );

  if (!config.isFetched) return <LoadingOverlay visible />;

  return (
    <Stack className={styles.classes.container} spacing="xs">
      {overview?.dashboard?.list?.length ? (
        <Group pt="xl" px="xl" grow>
          {isSpaceHasChildren ? (
            <SegmentedControl
              value={overview.dataLevel}
              size="lg"
              onChange={(dataLevel: DataLevelEnum) =>
                overview.onSetDataLevel(dataLevel)
              }
              data={[
                {
                  value: DataLevelEnum.All,
                  label: (
                    <Tooltip
                      label={
                        'View aggregated data for the selected space and all its subordinate spaces. \n' +
                        'This provides a comprehensive overview that includes all linked spaces.'
                      }
                    >
                      <Center>
                        <DateIcon width={18} height={18} />

                        <Box ml={10} fz={14}>
                          Include sub space
                        </Box>
                      </Center>
                    </Tooltip>
                  ),
                },
                {
                  value: DataLevelEnum.Local,
                  label: (
                    <Tooltip
                      label={
                        'View data exclusively for the currently selected space. \n' +
                        "Data from subordinate spaces won't be included."
                      }
                    >
                      <Center>
                        <MouseSquare width={18} height={18} />

                        <Box ml={10} fz={14}>
                          Selected space only
                        </Box>
                      </Center>
                    </Tooltip>
                  ),
                },
              ]}
            />
          ) : null}
        </Group>
      ) : null}

      <DashboardWrapper />

      {overview.isConfigMode ? (
        <Box
          className={styles.classes.addWidget}
          onClick={overview.onOpenWidgetsPanel}
        >
          <AddIcon width={46} height={46} />
        </Box>
      ) : null}

      <Box className={styles.classes.placeholder} />
    </Stack>
  );
}

const useStyles = createStyles(
  (theme, { isConfigMode }: { isConfigMode: boolean }) => ({
    container: {
      height: '100%',
      position: 'relative',
      zIndex: 0,

      '.space-dashboard-container': {
        padding: 0,
      },

      '.react-resizable-handle': {
        display: isConfigMode ? 'unset' : 'none',
      },
    },
    addWidget: {
      position: 'fixed',
      bottom: 40,
      right: 40,
      cursor: 'pointer',
      boxShadow: `0px 5px 12px rgba(38, 50, 56, 0.33)`,
      borderRadius: '50%',
      backgroundColor: theme.colors.blue_accent[4],
      color: theme.white,
      width: 69,
      height: 69,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
      transition: 'all 0.1s ease-in-out',

      '&:hover': {
        transform: 'scale(1.01)',
        boxShadow: `0px 5px 12px rgba(38, 50, 56, 0.5)`,
      },
    },
    placeholder: {
      height: 40,
      flexShrink: 0,
    },
  })
);
