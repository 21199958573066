import React from 'react';
import styled from 'styled-components';

import { SearchInput } from '@portals/core';
import { getStyledThemeColor } from '@portals/utils';

import { CreateCustomerSpaceButton } from '../../../../../desktop/pages/overview/overview-sidebar/organization-tree/components/CreateCustomerSpaceButton';

interface TreeHeaderProps {
  handleSearchOnChange: (value: string) => void;
  searchString: string;
  hideCreateCustomerSpaceButton?: boolean;
}

export function TreeHeader({
  handleSearchOnChange,
  searchString,
  hideCreateCustomerSpaceButton = false,
}: TreeHeaderProps) {
  return (
    <Container className="tree-header">
      <SearchInput
        value={searchString}
        placeholder="Find space / area..."
        onClear={() => handleSearchOnChange('')}
        onChange={(event) => handleSearchOnChange(event.target.value)}
        sx={{
          width: 'auto',
        }}
      />

      {hideCreateCustomerSpaceButton ? null : <CreateCustomerSpaceButton />}
    </Container>
  );
}

const Container = styled.div`
  padding: 5px 15px;
  width: 100%;
  background-color: ${getStyledThemeColor('gray150')};
  border-bottom: 1px solid ${getStyledThemeColor('gray300')};
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 10px;
  align-items: center;

  .search-input-container {
    height: 30px;
  }
`;
