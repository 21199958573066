import { ActionIcon, useMantineTheme } from '@mantine/core';
import React from 'react';

import { ReactComponent as Plus } from '@portals/icons/linear/add.svg';
import { useOpenModal } from '@portals/redux';

import { CreateCustomerSpaceModalProps } from '../../../../../modals';

export function CreateCustomerSpaceButton() {
  const theme = useMantineTheme();

  const openModal = useOpenModal();

  return (
    <ActionIcon
      radius="md"
      variant="outline"
      color="gray.3"
      size={40}
      onClick={() =>
        openModal<CreateCustomerSpaceModalProps['data']>(
          'CreateCustomerSpaceModal'
        )
      }
    >
      <Plus height={18} width={18} color={theme.colors.gray[7]} />
    </ActionIcon>
  );
}
