import {
  Button,
  createStyles,
  Group,
  Modal,
  Stack,
  Text,
  ModalProps as MantineModalProps,
} from '@mantine/core';
import React, { useState } from 'react';

import { SpaceType, useSpace } from '@portals/api/organizations';
import { ModalProps } from '@portals/framework';
import { ReactComponent as GPS } from '@portals/icons/linear/gps.svg';

import { OrganizationTree } from './move-device/OrganizationTree';

export interface ChangeSpaceModalProps
  extends ModalProps<{
    space: SpaceType | undefined;
    onSetSpaceId: (spaceId: number) => void;
  }> {}

export function ChangeSpaceModal({
  closeMe,
  data: { space, onSetSpaceId },
}: ChangeSpaceModalProps) {
  const { classes } = useStyles();

  const rootCustomerSpace = useSpace({
    spaceId: space?.root_customer_space_id,
  });

  const [selectedSpaceId, setSelectedSpaceId] = useState(space?.id ?? null);

  const onMove = async () => {
    if (!selectedSpaceId) {
      return;
    }

    onSetSpaceId(selectedSpaceId);

    closeMe();
  };

  return (
    <Modal
      opened
      size="lg"
      onClose={closeMe}
      radius="md"
      padding="xxl"
      styles={modalStyles}
      title={
        <Stack>
          <Text size="xl" color="blue_gray.9">
            Change space
          </Text>

          <Text size="sm" color="blue_gray.9">
            Choose space or area that you would like to add this device to.
          </Text>
        </Stack>
      }
    >
      <Stack h={420}>
        <OrganizationTree
          rootCustomerSpace={rootCustomerSpace}
          selectedSpaceId={selectedSpaceId}
          setSelectedSpaceId={setSelectedSpaceId}
          customLabel={({ node }) => (
            <Group position="apart" noWrap>
              <Text truncate>{node.title}</Text>

              {node.id === space?.id ? (
                <Group px="xs" spacing={2} noWrap>
                  <GPS height={16} width={16} />

                  <Text>Current Space</Text>
                </Group>
              ) : null}
            </Group>
          )}
        />
      </Stack>

      <Group position="right" pt="xxl" className={classes.footer}>
        <Button variant="default" onClick={closeMe}>
          Cancel
        </Button>

        <Button onClick={onMove}>Choose space</Button>
      </Group>
    </Modal>
  );
}

const useStyles = createStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.colors.gray[2]}`,
  },
}));

const modalStyles: MantineModalProps['styles'] = (theme) => ({
  header: {
    alignItems: 'flex-start',
    paddingBottom: theme.spacing.md,
    paddingRight: 32,
  },
});
